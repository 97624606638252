import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home";
import Mint from "./Screens/Mint";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
// import Loader from './Components/Loader';
// import "./i18n";
import "animate.css/animate.min.css";
import "./App.css";

function App() {
  return (
    <div className="wrapper">
      <Suspense fallback={null}>
        <BrowserRouter>
          <Header />
          <div className="maindiv">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mint" element={<Mint />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
