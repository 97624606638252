import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/images/logo.svg';
import menu from '../../Assets/images/menu.svg';
import Socialicons from '../socialicons';

const Header = () => {
  const [OpenMenu, setOpenMenu] = useState(false);

  return (
    <>
      <header className="head flexalign">
        <div className="container">
          <button
            className="mennu"
            onClick={() => {
              setOpenMenu(!OpenMenu);
            }}
          >
            <img src={menu} alt="" />
          </button>
          <a href="/" className="logodiv">
            <img src={logo} alt="logo" />
          </a>
          <div className="menu">
            <ul className="navmenu">
              <a href="/" className="active">
                Home
              </a>
              <a href="/#about">About</a>
              <a href="/#road">RoadMap</a>
              <a href="/#team">Team</a>
              <a href="/#faq">FAQs</a>
            </ul>
          </div>
          <div className="rightdiv">
            <Socialicons />
            <Link className="btn headbtn" to="/mint">
              Mint Now
            </Link>
          </div>
        </div>
      </header>
      <div
        className={`mobile-menu ${OpenMenu ? 'open-menu' : ''}`}
        onClick={() => setOpenMenu(false)}
      >
        <div className="iner-head">
          <a href="/" onClose={() => setOpenMenu(false)} className="logodiv">
            <img src={logo} alt="logo" />
          </a>
          <div className="menu">
            <ul className="navmenu">
              <Link
                onClose={() => setOpenMenu(false)}
                to="/"
                className="active"
              >
                Home
              </Link>
              <Link onClose={() => setOpenMenu(false)} to="/">
                About
              </Link>
              <Link onClose={() => setOpenMenu(false)} to="/">
                RoadMap
              </Link>
              <Link onClose={() => setOpenMenu(false)} to="/">
                Team
              </Link>
              <Link onClose={() => setOpenMenu(false)} to="/">
                FAQs
              </Link>
            </ul>
          </div>
          <Socialicons />
        </div>
      </div>
    </>
  );
};

export default Header;
