import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { ADDRESS, ABI } from "../../config";
import mintimg from "../../Assets/images/mintimg.png";

const Mint = () => {
  const [rastaPandaContract, setRastaPandaContract] = useState(null);
  const [connected, setConnected] = useState(false);
  const [userAddress, setUserAddress] = useState(false);
  const [pandas, setPandas] = useState(1);
  const [totalSupply, setTotalSupply] = useState(0);
  const [pandaPrice, setPandaPrice] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [saleIsActive, setSaleIsActive] = useState(0);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const init = async () => {
      window.web3 = new Web3(window.ethereum);
      const rastaPandaContract = new window.web3.eth.Contract(ABI, ADDRESS);
      setRastaPandaContract(rastaPandaContract);
      const totalSupply = await rastaPandaContract.methods.totalSupply().call();
      const pandaPrice = await rastaPandaContract.methods
        .getPandaPrice()
        .call();
      const maxSupply = await rastaPandaContract.methods.getMaxSupply().call();
      const saleIsActive = await rastaPandaContract.methods
        .getMintEnabled()
        .call();
      setTotalSupply(totalSupply);
      setPandaPrice(pandaPrice);
      setMaxSupply(maxSupply);
      setSaleIsActive(saleIsActive);
    };
    init();
  }, []);

  const isMetaMaskInstalled = () =>
    Boolean(window.ethereum && window.ethereum.isMetaMask);

  const isWeb3Defined = () => Boolean(window.ethereum);

  const isValidChainId = async () => {
    if (isWeb3Defined()) {
      const web3 = new Web3(window.ethereum);
      const chainID = await web3.eth.net.getId();
      if (chainID === 1) {
        return true;
      }
      return false;
    }
    return false;
  };

  const connectMetamaks = async () => {
    if (isMetaMaskInstalled) {
      if (typeof window.web3 !== "undefined") {
        window.web3 = new Web3(window.ethereum);
      } else {
        alert("No Ethereum interface injected into browser. Read-only access");
      }
      const { ethereum } = window;
      ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((accounts) => {
          window.web3.eth.net.getNetworkType().then(async () => {
            const validChain = await isValidChainId();
            if (!validChain) {
              alert("Unsupported network. Please use Ethereum Net");
            } else {
              setUserAddress(accounts[0].toLowerCase());
              setConnected(true);
            }
          });
        })
        .catch(() => {
          window.alert("User Rejected to connect metamask");
        });
    } else {
      window.alert("Please install Metamask extension");
    }
  };

  const mint = async () => {
    try {
      if (rastaPandaContract) {
        const price =
          parseFloat(window.web3.utils.fromWei(pandaPrice, "ether")) * pandas;
        const gasAmount = await rastaPandaContract.methods
          .mint(pandas)
          .estimateGas({
            from: userAddress,
            value: window.web3.utils.toWei(price.toString(10), "ether"),
          });
        setDisabled(true);
        rastaPandaContract.methods
          .mint(pandas)
          .send({
            from: userAddress,
            value: window.web3.utils.toWei(price.toString(10), "ether"),
            gas: String(gasAmount),
          })
          .on("receipt", async () => {
            const tSupply = await rastaPandaContract.methods
              .totalSupply()
              .call();
            setTotalSupply(tSupply);
            setDisabled(false);
          })
          .on("error", (error) => {
            setDisabled(false);
            console.log(error);
          });
      } else {
        alert("Wallet not connected");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div id="mainwrapper" className="mint">
      <div className="mintmain">
        <div className="container">
          <div className="mintbx">
            <img src={mintimg} alt="" />
            <div className="textm">
              {`${connected ? "00" : "-"} / 8888 + 12 Legendary`}
            </div>
            <p>
              Pre-sale Starts 16:20 <b>BST</b>
            </p>
            {!connected && <p>Only 420 available in the Mini-mint!</p>}
            {!connected && (
              <p>Raffle for a trip to Amsterdam after 420 minted!</p>
            )}
            {connected && (
              <div className="conwa">
                <p>Public Sale Open</p>
                <p>{`${totalSupply} / ${maxSupply}`}</p>
                <div className="number">
                  <span
                    className="minus"
                    onClick={() => {
                      if (pandas > 1) {
                        setPandas(pandas - 1);
                      } else {
                        setPandas(1);
                      }
                    }}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={pandas}
                    onChange={(event) => {
                      let value = 0;
                      if (event.target.value !== "") {
                        value =
                          parseInt(event.target.value) > 50
                            ? 50
                            : parseInt(event.target.value);
                      }
                      setPandas(value);
                    }}
                  />
                  <span
                    className="plus"
                    onClick={() => {
                      if (pandas <= 50) {
                        setPandas(pandas + 1);
                      } else {
                        setPandas(50);
                      }
                    }}
                  >
                    +
                  </span>
                </div>
              </div>
            )}
            {connected ? (
              <button
                className="btn primarybtn"
                onClick={() => mint()}
                disabled={!saleIsActive || disabled}
              >
                Mint
              </button>
            ) : (
              <button
                className="btn primarybtn"
                onClick={() => connectMetamaks()}
              >
                Connect
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
