import React from 'react';
// import tiktok from '../Assets/images/tiktok.svg';
// import fb from '../Assets/images/fb.svg';
import Telegram from '../Assets/images/Telegram.svg';
import riddet from '../Assets/images/riddet.svg';
import twitter from '../Assets/images/twitter.svg';
import opensea from '../Assets/images/opensea.svg';
import Medium from '../Assets/images/Medium.svg';

const Socialicons = () => (
  <ul className="soclicons">
    <li>
      <a href="https://t.me/RastaPandaPotClub" target="_blank" rel="noreferrer">
        <img src={Telegram} alt="Insta" />
      </a>
    </li>
    {/* <li>
        <a href="https://facebook.com" target="_blank">
        <img src={fb} alt="Facebook" />
        </a>
      </li>
      <li>
        <a href="https://tiktok.com" target="_blank">
        <img src={tiktok} alt="titok" />
        </a>
      </li> */}
    <li>
      <a href="https://discord.gg/m4QZwk9yAN" target="_blank" rel="noreferrer">
        <img src={riddet} alt="discord" />
      </a>
    </li>
    <li>
      <a
        href="https://twitter.com/RastaPandaPC"
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitter} alt="twitter" />
      </a>
    </li>
    <li>
      <a href="https://opensea.com" target="_blank" rel="noreferrer">
        <img src={opensea} alt="opensea" />
      </a>
    </li>
    <li>
      <a
        href="https://medium.com/@RastaPandaPotClub"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Medium} alt="medium" />
      </a>
    </li>
  </ul>
);

export default Socialicons;
