import React from 'react';
import Socialicons from '../socialicons';
import logo from '../../Assets/images/logo.svg';

const Footer = () => (
  <footer>
    <div className="container ">
      <div className="fleftconte">
        <a href="/">
          <img src={logo} alt="" />
        </a>
        <p>© 2022 All Rights Reserved</p>
      </div>
      <div className="flinks">
        <h3>Links</h3>
        <ul className="linnav">
          <li>
            <a className="active" href="/">
              Home
            </a>
          </li>
          <li>
            <a href="/#about">About</a>
          </li>
          <li>
            <a href="/#roadmap">Roadmap</a>
          </li>
          <li>
            <a href="/#team">Team</a>
          </li>
          <li>
            <a href="/#faqs">FAQs</a>
          </li>
          <li>
            <a
              href="https://verify.raritysniper.com/"
              target="_blank"
              rel="noreferrer"
            >
              As Seen On
            </a>
          </li>
        </ul>
      </div>
      <div className="fsociallinks">
        <h3>Social</h3>
        <Socialicons />
      </div>
    </div>
  </footer>
);

export default Footer;
