import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import leaf from "../../Assets/images/leaf.png";
import rastaimg from "../../Assets/images/rastapanda.png";
import bambooframe from "../../Assets/images/bambooframe.png";
import pandaleft from "../../Assets/images/pandaleft.png";
import pandaright from "../../Assets/images/pandaright.png";
import icon1 from "../../Assets/images/icon1.png";
import icon2 from "../../Assets/images/icon2.png";
import icon3 from "../../Assets/images/icon3.png";
import icon4 from "../../Assets/images/icon4.png";
import icon5 from "../../Assets/images/icon5.png";
import icon6 from "../../Assets/images/icon6.png";
import icon7 from "../../Assets/images/icon7.png";
import icon8 from "../../Assets/images/icon8.png";
// import videopanda from '../../Assets/images/rppcslideshow.mp4';
import p1 from "../../Assets/images/p1.png";
import p2 from "../../Assets/images/p2.png";
import p3 from "../../Assets/images/p3.png";
import p4 from "../../Assets/images/p4.png";
import p5 from "../../Assets/images/p5.png";
// import p6 from "../../Assets/images/p6.png";
import p7 from "../../Assets/images/p7.png";
import p8 from "../../Assets/images/p8.png";
import p9 from "../../Assets/images/p9.png";
import p10 from "../../Assets/images/p10.png";
import p11 from "../../Assets/images/p11.png";
import p12 from "../../Assets/images/p12.png";
import p13 from "../../Assets/images/p13.png";
import p14 from "../../Assets/images/p14.png";
import p15 from "../../Assets/images/p15.png";
import p16 from "../../Assets/images/p16.png";
import p17 from "../../Assets/images/p17.png";
import p18 from "../../Assets/images/p18.png";
import smoke from "../../Assets/images/smoke.png";
import xo from "../../Assets/images/xo.png";
import mj from "../../Assets/images/mj-1.png";
import mj1 from "../../Assets/images/mj1.png";
import animguide from "../../Assets/images/animguide.png";
import pandagif from "../../Assets/images/NFT.gif";
import line from "../../Assets/images/line.png";
import roadmapglowr from "../../Assets/images/roadmapglowr.png";
import roadmapglowl from "../../Assets/images/roadmapglowl.png";
import team1 from "../../Assets/images/team1.png";
import team2 from "../../Assets/images/team2.png";
import team3 from "../../Assets/images/team3.png";
import team4 from "../../Assets/images/team4.png";
import team5 from "../../Assets/images/team5.png";
import arrowdown from "../../Assets/images/arrowdown.png";
import talk from "../../Assets/images/talk.png";
import raritysniper from "../../Assets/images/logo1.png";
import Mintyscore from "../../Assets/images/logo3.png";
import upcoming from "../../Assets/images/logo2.png";

const Home = () => {
  const [roadmapDivsClasses, setRoadmapDivsClasses] = useState({
    div1: "",
    div2: "",
    div3: "",
    div4: "",
    div5: "",
  });

  const [faqAnswers, setFaqAnswers] = useState({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
  });

  const renderImages = () => (
    <>
      <img src={p1} alt="" />
      <img src={p2} alt="" />
      <img src={p3} alt="" />
      <img src={p4} alt="" />
      <img src={p5} alt="" />
      <img src={p7} alt="" />
      <img src={p8} alt="" />
      <img src={p9} alt="" />
      <img src={p10} alt="" />
      <img src={p11} alt="" />
      <img src={p12} alt="" />
      <img src={p13} alt="" />
      <img src={p14} alt="" />
      <img src={p15} alt="" />
      <img src={p16} alt="" />
      <img src={p17} alt="" />
      <img src={p18} alt="" />
    </>
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 3608) {
        setRoadmapDivsClasses({
          div1: "active",
          div2: "",
          div3: "",
          div4: "",
          div5: "",
        });
      }
      if (window.scrollY > 3808) {
        setRoadmapDivsClasses({
          div1: "active",
          div2: "active",
          div3: "",
          div4: "",
          div5: "",
        });
      }
      if (window.scrollY > 4008) {
        setRoadmapDivsClasses({
          div1: "active",
          div2: "active",
          div3: "active",
          div4: "",
          div5: "",
        });
      }
      if (window.scrollY > 4208) {
        setRoadmapDivsClasses({
          div1: "active",
          div2: "active",
          div3: "active",
          div4: "active",
          div5: "",
        });
      }
      if (window.scrollY > 4408) {
        setRoadmapDivsClasses({
          div1: "active",
          div2: "active",
          div3: "active",
          div4: "active",
          div5: "active",
        });
      }
      if (window.scrollY <= 3608) {
        setRoadmapDivsClasses({
          div1: "",
          div2: "",
          div3: "",
          div4: "",
          div5: "",
        });
      }
    });
  }, []);

  return (
    <div id="mainwrapper" className="homepage ">
      <div className="mainbanner flexalign">
        <div className="container ">
          <div className="topcontent textcenter">
            <img src={leaf} alt="RastaPanda" className="gloww" />
            <img src={rastaimg} alt="RastaPanda" />
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1} />
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <Link to="mint" className="btn primarybtn">
                Mint Now
              </Link>
            </ScrollAnimation>
          </div>
        </div>
        <div className="leftimg">
          <img src={pandaleft} alt="panda left" />
        </div>
        <div className="rightimg">
          <img src={pandaright} alt="panda right" />
        </div>
      </div>
      <div className="marquee">
        <div>
          <span>8888 + 12 Legendary</span>
          <span>180 Traits</span>
          <span>Pre-sale Price 0.06942 Eth</span>
          <span>Public Sale TBD</span>
          <span>Pre-sale Mint April 20th 1620 BST</span>
          <span>Public Mint TBD</span>
          <span>8888 + 12 Legendary</span>
          <span>180 Traits</span>
          <span>Pre-sale Price 0.06942 Eth</span>
          <span>Public Sale TBD</span>
          <span>Pre-sale Mint April 20th 1620 BST</span>
          <span>Public Mint TBD</span>
          <span>8888 + 12 Legendary</span>
          <span>180 Traits</span>
          <span>Pre-sale Price 0.06942 Eth</span>
          <span>Public Sale TBD</span>
          <span>Pre-sale Mint April 20th 1620 BST</span>
          <span>Public Mint TBD</span>
        </div>
      </div>
      <div className="abutsect flexalign" id="about">
        <div className="container">
          <div className="leftsect">
            <ScrollAnimation animateIn="animate__fadeIn" duration={3}>
              <div className="imgbef">
                <img src={pandagif} alt="" />
                <img className="bfreimg" src={bambooframe} alt="" />
              </div>
            </ScrollAnimation>
          </div>
          <div className="rightsect">
            <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
              <h2>WELCOME TO RPPC</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1.3}>
              <p>
                Welcome all to the Rasta Panda Pot Club. We are a community
                collection of 8888 + 12 legendary, unique pot smoking, bamboo
                munching, freedom loving Pandas jammin on the Ethereum
                blockchain.
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1.3}>
              <p>Holders will enjoy the following benefits:</p>
            </ScrollAnimation>
            <ul className="iconsect">
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={1.5}>
                  <img src={icon1} alt="" />
                  <p>Join an inclusive community of shared interests</p>
                </ScrollAnimation>
              </li>

              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={1.5}>
                  <img src={icon2} alt="" />
                  <p>Right to vote over community funds worth up to $420,000</p>
                </ScrollAnimation>
              </li>
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={1.8}>
                  <img src={icon3} alt="" />
                  <p>Chance to win 0.42 eth weekly giveaways</p>
                </ScrollAnimation>
              </li>
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={1.8}>
                  <img src={icon4} alt="" />
                  <p>Receive airdrops of 1 / 1 art</p>
                </ScrollAnimation>
              </li>
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={2.1}>
                  <img src={icon5} alt="" />
                  <p>
                    Exclusive funded IRL smoke-outs in places where weed is
                    legalized
                  </p>
                </ScrollAnimation>
              </li>
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={2.1}>
                  <img src={icon6} alt="" />
                  <p>Merch store giveaways</p>
                </ScrollAnimation>
              </li>
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={2.4}>
                  <img src={icon7} alt="" />
                  <p>Earn our community token</p>
                </ScrollAnimation>
              </li>
              <li>
                <ScrollAnimation animateIn="animate__fadeInUp" duration={2.4}>
                  <img src={icon8} alt="" />
                  <p>
                    Take pride in providing school equipment to children in
                    Ethiopia and Jamaica
                  </p>
                </ScrollAnimation>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mainslides paddpost">
        <div className="textcenter textconet">
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.2}>
            <h2>Rasta Panda Traits</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <p>
              Each Rasta Panda will be a unique collectible with incredible
              characteristics and insane attributes, to represent us a
              community! We want to create a classic brand that will be
              recognized across the cannabis and NFT world that grows into the
              future.
            </p>
          </ScrollAnimation>
          <img src={smoke} alt="" />
        </div>
        <div className="marslider">
          <div className="slidess">
            <marquee direction="left">
              {renderImages()}
              {renderImages()}
              {renderImages()}
              {renderImages()}
              {renderImages()}
              {renderImages()}
            </marquee>
          </div>
        </div>
        <div className="marslider">
          <div className="slidess">
            <marquee direction="right">
              {renderImages()}
              {renderImages()}
              {renderImages()}
              {renderImages()}
              {renderImages()}
              {renderImages()}
            </marquee>
          </div>
        </div>
      </div>
      <div className="raritsect paddpost">
        <div className="textcenter textconet">
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.2}>
            <h2>Rarities</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <p>
              Rarity will be determined by traits such as the wild fur, eclectic
              clothing, flashy jewelry, smoke of choice, wacky headwear, and
              Rasta hairstyles. Staking your unique Rasta Panda will earn you
              the Superstrain Bambud Ganja NFT.
            </p>
            <p>
              When a Rasta Panda chongs their SuperStrain they will be able to
              mint their Super Stoned Alter-Ego! The Super Stoned Alter-Egos
              will bring crazy new characters and styles to the collection ready
              for us to elevate the project to new highs!
            </p>
          </ScrollAnimation>
          <img src={xo} alt="" />
        </div>
        <img className="fullwth" src={animguide} alt="" />
      </div>
      <div className="roadmap paddpost" id="road">
        <div className="textcenter textconet">
          <img className="leftefimg" src={mj1} alt="" />
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.2}>
            <h2>Roadmap</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <p>
              Join the Rasta Pandas from 2022 as we embark on our cannabis
              infused NFT adventure.
            </p>
          </ScrollAnimation>
          <img src={mj} alt="" />
        </div>
        <div className="container">
          <div className={`roadmp ${roadmapDivsClasses.div1}`}>
            <div className="leftcontent rdcont">
              <div className="rodcont">
                <h4> Community Fund</h4>
                <p>
                  10% of primary sales to the community fund +10% of all
                  secondary sales revenue to community fund. Owners will have
                  voting rights over how to funds are spent and how the project
                  develops into the future.
                </p>
              </div>
              <div className="rleftimg">
                <img src={roadmapglowl} alt="" />
              </div>
            </div>
            <div className="middleline">
              <img src={line} alt="" className="middleimg" />
            </div>
            <div className="rightcontent rdcont">
              <div className="rrightimg">
                <img src={roadmapglowr} alt="" />
              </div>
              <div className="rodcont">
                <h4> Charity Donation</h4>
                <p>
                  5% to charities providing schools and sports equipment in
                  Ethiopia and Jamaica. We know charity is not a utility but
                  this is our community&apos;s first step in changing the lives
                  of others by using the power of community
                </p>
              </div>
            </div>
          </div>
          <div className={`roadmp ${roadmapDivsClasses.div2}`}>
            <div className="leftcontent rdcont">
              <div className="rodcont">
                <h4> Community Creative Commissions</h4>
                <p>
                  The Rasta Panda team and community will commission community
                  members to create their art giving the creatives amongst us
                  the opportunity to shine. Whether it is music, photography,
                  real-world or digital art or something else we want our
                  members to be recognised for their talents!
                </p>
              </div>
              <div className="rleftimg">
                <img src={roadmapglowl} alt="" />
              </div>
            </div>
            <div className="middleline">
              <img src={line} alt="" className="middleimg" />
            </div>
            <div className="rightcontent rdcont">
              <div className="rrightimg">
                <img src={roadmapglowr} alt="" />
              </div>
              <div className="rodcont">
                <h4> Real Life Meetups</h4>
                <p>
                  Global chills sessions with your fellow Pandas. Each smoke out
                  will be given a budget from the community funds and free merch
                  given to the Pandas who turn up to light up - only where it is
                  legal of course!!
                </p>
              </div>
            </div>
          </div>
          <div className={`roadmp ${roadmapDivsClasses.div3}`}>
            <div className="leftcontent rdcont">
              <div className="rodcont">
                <h4> NFT Staking</h4>
                <p>
                  Stakers will enter a raffle to earn 1 of 3 Super Stoned NFTs
                  all with different rarity + Super Stoned collection release
                </p>
              </div>
              <div className="rleftimg">
                <img src={roadmapglowl} alt="" />
              </div>
            </div>
            <div className="middleline">
              <img src={line} alt="" className="middleimg" />
            </div>
            <div className="rightcontent rdcont">
              <div className="rrightimg">
                <img src={roadmapglowr} alt="" />
              </div>
              <div className="rodcont">
                <h4> 0.42 Eth Weekly Raffle</h4>
                <p>
                  After selling out, community members who use their Rasta Panda
                  as their profile picture on twitter and discord and stake
                  their Rasta Panda will be entered into the weekly 0.42 Eth
                  giveaway
                </p>
              </div>
            </div>
          </div>
          <div className={`roadmp ${roadmapDivsClasses.div4}`}>
            <div className="leftcontent rdcont">
              <div className="rodcont">
                <h4> Airdrops</h4>
                <p>
                  Random holders will receive free NFTs, real life prints and
                  merch giveaways
                </p>
              </div>
              <div className="rleftimg">
                <img src={roadmapglowl} alt="" />
              </div>
            </div>
            <div className="middleline">
              <img src={line} alt="" className="middleimg" />
            </div>
            <div className="rightcontent rdcont">
              <div className="rrightimg">
                <img src={roadmapglowr} alt="" />
              </div>
              <div className="rodcont">
                <h4> Metaverse Clubhouse</h4>
                <p>
                  Decentraland or Sandbox Clubhouse for the Pandas to get
                  together in the virtual world
                </p>
              </div>
            </div>
            <img className="leftefimg" src={mj1} alt="" />
          </div>
          <div className={`roadmp ${roadmapDivsClasses.div5}`}>
            <div className="leftcontent rdcont">
              <div className="rodcont">
                <h4> 3d Avatar collection</h4>
                <p>Avatar collection to be used in web3 applications</p>
              </div>
              <div className="rleftimg">
                <img src={roadmapglowl} alt="" />
              </div>
            </div>
            <div className="middleline">
              <img src={line} alt="" className="middleimg" />
            </div>
            <img className="rightefimg" src={mj} alt="" />

            <div className="rightcontent rdcont">
              <div className="rrightimg">
                <img src={roadmapglowr} alt="" />
              </div>
              <div className="rodcont">
                <h4> Merch Store and Brand Partnerships</h4>
                <p>
                  Partnering up with lifestyle brands to provide exclusive Rasta
                  Panda collabs for clothing and smoking accessories
                </p>
              </div>
            </div>
          </div>
          <img className="botmleftimg" src={mj} alt="" />
        </div>
      </div>
      <div className="teamsect paddpost" id="team">
        <div className="textcenter textconet">
          <img className="leftefimg" src={mj} alt="" />
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.2}>
            <h2>Team</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <p>
              A combination of DeFi degens reformed into NFT lovers, pot
              smoking, freedom loving blockchain builders and enthusiasts.
            </p>
          </ScrollAnimation>
        </div>
        <div className="container">
          <div className="teamsbox">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <img src={team1} alt="" />
              <div className="teamcont">
                <h3>Alpha Marley</h3>
                <p>Co-Founder</p>
                <p>
                  A stoner who truly believes the power of community and
                  blockchain will change the world for good.
                </p>
              </div>
            </ScrollAnimation>
          </div>

          <div className="teamsbox">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <img src={team2} alt="" />
              <div className="teamcont">
                <h3>Yankee Green</h3>
                <p>Co-Founder</p>
                <p>
                  Lover of all things Bitcoin and Bud, there isn&apos;t much
                  this guy doesn&apos;t know about hash power.
                </p>
              </div>
            </ScrollAnimation>
          </div>
          <div className="teamsbox">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <img src={team3} alt="" />
              <div className="teamcont">
                <h3>Bozo Dutchie</h3>
                <p>Lead Developer</p>
                <p>
                  Building blockchain solutions since 2017, a veteran in the
                  crypto space who loves a toke or two on that sweet delicious
                  green.
                </p>
              </div>
            </ScrollAnimation>
          </div>
          <div className="teamsbox">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <img src={team4} alt="" />
              <div className="teamcont">
                <h3>Bongo Chode</h3>
                <p>Community Mod</p>
                <p>
                  A connoisseur of the devils lettuce, you will not find this
                  guy without a Doobie
                </p>
              </div>
            </ScrollAnimation>
          </div>
          <div className="teamsbox">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <img src={team5} alt="" />
              <div className="teamcont">
                <h3>Mary Jane</h3>
                <p>Marketing Strategist</p>
                <p>Whatever I do, I do it better high.</p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="faqmain paddpost" id="faq">
        <div className="textcenter textconet">
          <img className="leftefimg" src={talk} alt="" />
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.2}>
            <h2>FAQS</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <p>
              A combination of DeFi degens reformed into NFT lovers, pot
              smoking, freedom loving blockchain builders and enthusiasts.
            </p>
          </ScrollAnimation>
        </div>
        <div className="container">
          <div className="faqse">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <div className={`faqbx ${faqAnswers.q1 ? "active" : ""}`}>
                <div
                  className="faqques"
                  onClick={() =>
                    setFaqAnswers({
                      q2: false,
                      q3: false,
                      q4: false,
                      q1: !faqAnswers.q1,
                    })
                  }
                >
                  When is the mint date?
                  <div className="imgfa">
                    <img src={arrowdown} alt="" />
                  </div>
                </div>

                <div className="faqanswer">
                  Pre-sale mint date is 4/20 2022. Minting will begin 1620 BST
                  and 10% of the total supply will be available! Holders will be
                  whitelisted for public mint at the same price as the pre-sale
                  price.
                  <br />
                  Public sale TBA
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <div className={`faqbx ${faqAnswers.q2 ? "active" : ""}`}>
                <div
                  className="faqques"
                  onClick={() =>
                    setFaqAnswers({
                      q1: false,
                      q3: false,
                      q4: false,
                      q2: !faqAnswers.q2,
                    })
                  }
                >
                  How do I get whitelisted?
                  <div className="imgfa">
                    <img src={arrowdown} alt="" />
                  </div>
                </div>

                <div className="faqanswer">
                  Join us on our socials for a variety of ways to get yourself
                  greenlisted. Discord is the place to be for all our alpha.
                  Once selected simply fill in your information on the greenlist
                  form so we can cross reference the registration then wait for
                  the mint date!
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <div className={`faqbx ${faqAnswers.q3 ? "active" : ""}`}>
                <div
                  className="faqques"
                  onClick={() =>
                    setFaqAnswers({
                      q2: false,
                      q1: false,
                      q4: false,
                      q3: !faqAnswers.q3,
                    })
                  }
                >
                  What is the pre-sale price?
                  <div className="imgfa">
                    <img src={arrowdown} alt="" />
                  </div>
                </div>
                <div className="faqanswer">
                  Pre-sale price will be 0.06942 Eth
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <div className={`faqbx ${faqAnswers.q4 ? "active" : ""}`}>
                <div
                  className="faqques"
                  onClick={() =>
                    setFaqAnswers({
                      q2: false,
                      q3: false,
                      q1: false,
                      q4: !faqAnswers.q4,
                    })
                  }
                >
                  What is the public sale price?
                  <div className="imgfa">
                    <img src={arrowdown} alt="" />
                  </div>
                </div>
                <div className="faqanswer">TBA</div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="logssec">
        <div className="container">
          <h2>AS SEEN ON</h2>
          <div className="logoss">
            <a
              href="https://upcomingnft.net/event/rasta-panda-pot-club/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={upcoming} alt="" />
            </a>
            <a
              href="https://app.mintyscore.com/project/6e0053ff-0c92-4a32-b659-8b037786ef12"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Mintyscore} alt="" />
            </a>
            <a
              href="https://raritysniper.com/nft-drops-calendar"
              target="_blank"
              rel="noreferrer"
            >
              <img src={raritysniper} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
